import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  host: { 'class': 'login-container' },
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  password:string = "";
  username:string = "";
  showErrorMessage:boolean = false;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.form = fb.group({
      'username' : [null, Validators.required],
      'password' : [null, Validators.required],
    });
  }

  ngOnInit() {

  }

  login(_event) {
    console.log('Submit login form.');
    this.showErrorMessage = false;
    let response = this.authService.login({
        'username' : this.username,
        'password' : this.password
    }).subscribe(
			_response => {
	      if (_response.status === "error") {
	        this.showErrorMessage = true;
	      }
	      else if (_response.status === "success") {
	        this.authService.setUser(_response.user);
	        this.router.navigate(['/order']);
	      }
	    },
			error => {
				alert('[Login] => There is a configuration problem with the server. Make sure you are on https://www.favuzzi.com/pos/');
			}
		);
  }

}
