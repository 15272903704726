import { Injectable } from '@angular/core';
import { Http, RequestOptionsArgs, RequestOptions } from '@angular/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authenticateStatus = new BehaviorSubject<boolean>(false);
  private userStatus = new BehaviorSubject<object>({});

  apiUrl:string = '';
  optionsArgs:RequestOptionsArgs = { withCredentials: true };
  options = new RequestOptions(this.optionsArgs);

  constructor(
    public http:Http,
    private config:AppConfig
  ) {
    this.apiUrl = config.getConfig('apiUrl');
  }

  isAuthenticated(): Observable<boolean> | boolean {
    console.log('Checking if the user is authenticated.');
    return this.http.get(this.apiUrl + 'authenticate', this.options)
      .pipe(
        map(_response => {
          let response = _response.json();
					console.log('====', response);
          if (response.status === 'success') {
            console.log('User is authenticated.');
            this.authenticateStatus.next(true);
            return true;
          } else {
            console.log('User is not authenticated.');
            return false;
          }
        })
      );
  }

  login(_credentials:object) {
    console.log('Sending login request.');
    return this.http.post(this.apiUrl + 'login', JSON.stringify(_credentials), this.options)
      .pipe(map(_response => _response.json()));
  }

  logout() {
    console.log('Logging out.');
    localStorage.clear();
    this.userStatus.next({});
    this.authenticateStatus.next(false);
    return this.http.get(this.apiUrl + 'logout', this.options)
      .pipe(map(_response => _response.json()));
  }


  getAuthenticateStatus() {
    return this.authenticateStatus.asObservable();
  }

  getUserStatus() {
    return this.userStatus.asObservable();
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user')) || this.userStatus;
  }

  setUser(_user) {
    this.userStatus.next(_user);
    localStorage.setItem('user', JSON.stringify(_user));
  }

}
