import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, RequestOptionsArgs, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { catchError } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  apiUrl:string = '';
	clientNumber:string;
  optionsArgs:RequestOptionsArgs = {
		withCredentials: true,
	};
  options:RequestOptions = new RequestOptions(this.optionsArgs);

  constructor(public http:Http, private config: AppConfig) {
    console.log('Data service connected');
    this.apiUrl = config.getConfig('apiUrl');
		this.clientNumber = config.getConfig('clientNumber');
  }

  getData() {
    return this.http.post(this.apiUrl + 'get-data', JSON.stringify({ clientNumber : this.clientNumber }), this.options)
      .pipe(map(_response => _response.json()));
  }

	getLocalProducts(): Observable<any> {
		return this.http.get("./assets/json/extra-products.json?v202221125").pipe(map(_response => _response.json()));
	}

  saveOrder(_order) {
    console.log('Saving order');
    let order = cloneDeep(_order);

    let keepProprties:string[] = [
			"id",
			"code",
			"component_code",
			"component_description",
			"component_price",
			"component_total",
			"format",
			"name_fr",
			"name_en",
			"quantity",
			"price",
			"taxable_gst",
			"taxable_pst",
			"total",
			"weight",
			"quantity_per_case"
		];

		let index = order.products.length - 1;

		while (index >= 0) {
			let product = order.products[index];

			//remove deposits because we don't want it in this format on the server
			if ((product.hasOwnProperty('is_component')) && (product.is_component)) {
				order.products.splice(index, 1);
			}
			else {

		    for (let property in product) {
		      if ((keepProprties.indexOf(property) === -1)) {
		        delete product[property];
		      }
		    }

				if ((product.hasOwnProperty('component_code')) && (product.component_code === null)) {
					delete product.component_code;
					delete product.component_description;
					delete product.component_price;
					delete product.component_total;
				}
			}

			index -= 1;
		};

    return this.http.post(this.apiUrl + 'save-order', JSON.stringify(order), this.options)
      .pipe(map(_response => _response.json()));

  }
}
