import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean{
      /*if (this.authService.isAuthenticated()) {
        console.log('INNN');
        return true;
      }
      else {
        console.log('OUTT');
        return false;
      }*/
      return this.authService.isAuthenticated();
      /*
      if (this.authService.isAuthenticated()) {
        console.log('TRUER');
        return true;
      }
      else {
        console.log('FALSER');
        return false;
      }
      */
  }
}
