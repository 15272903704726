import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../app.config';
import { UserInterface } from '../../../interfaces/interfaces.type';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
	host: { 'id': 'footer' },

})
export class FooterComponent implements OnInit {

	authenticateStatus:Observable<boolean>;
	versionNumber:string;
	userStatus:Observable<{}>;
	user:UserInterface;

  constructor(
		private authService:AuthService,
    public config: AppConfig
  ) { }

  ngOnInit() {
    this.versionNumber = this.config.getConfig('versionNumber');

		this.authenticateStatus = this.authService.getAuthenticateStatus();
		this.authenticateStatus.subscribe( _response => {
			console.log('Authenticate status changed.', _response);
		});

		this.userStatus = this.authService.getUserStatus();
		this.userStatus.subscribe( _response => {
			console.log('User status changed.', _response);
			this.user = this.authService.getUser();
		});

  }

}
