import {
    Directive, ElementRef, AfterViewChecked,
    Input, HostListener
} from '@angular/core';

@Directive({
    selector: '[appMatchHeight]'
})
export class MatchHeightDirective implements AfterViewChecked {

    // class name to match height
    @Input()
    appMatchHeight: any;

    constructor(private el: ElementRef) {
    }

    ngAfterViewChecked() {
        // call our matchHeight function here later
        this.matchHeight(this.el.nativeElement, this.appMatchHeight);
    }

    @HostListener('window:resize')
    onResize() {
        // call our matchHeight function here later
        this.matchHeight(this.el.nativeElement, this.appMatchHeight);
    }

		findAncestor (el, sel) {
    	while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
    	return el;
		}

    matchHeight(parent: HTMLElement, classname: string) {

				if (!parent) return;
        const children = parent.getElementsByClassName(classname);

        if (!children) return;

				// reset all children height
        Array.from(children).forEach((x: HTMLElement) => {
            x.style.height = 'initial';
				});

				const tableHeader = <HTMLElement>parent.getElementsByTagName('thead')[0];
				const maxHeight = (parent.getBoundingClientRect().height) - (tableHeader.getBoundingClientRect().height);

        // apply height
        Array.from(children)
            .forEach((x: HTMLElement) => x.style.height = `${maxHeight}px`);
    }
}
