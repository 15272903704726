import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AppConfig {

  private config: Object = null;
  private env: Object = null;

  constructor(private http: Http) {

  }

  /**
   * Use to get the data found in the config file
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the env file
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http.get('./assets/json/env.json')
        .pipe(
          map(res => res.json()),
          catchError((error: any): any => {
            console.log('Configuration file "env.json" could not be read');
            resolve(true);
            return Observable.throw(error.json().error || 'Server error');
          })
        )
        .subscribe((envResponse) => {
          console.log('Environment file loaded.');
          this.env = envResponse;
          let request: any = null;

          switch (envResponse.env) {
            case 'production': {
              request = this.http.get('./assets/json/config.' + envResponse.env + '.json?v=' + String(Math.random()));
            } break;

            case 'development': {
              request = this.http.get('./assets/json/config.' + envResponse.env + '.json?v=' + String(Math.random()));
            } break;

						case 'vyx': {
							request = this.http.get('./assets/json/config.' + envResponse.env + '.json?v=' + String(Math.random()));
						} break;

            case 'default': {
              console.error('Environment file is not set or invalid');
              resolve(true);
            } break;
          }

          if (request) {
            request
              .pipe(
                map((res: Response) => res.json()),
                catchError((error: any) => {
                  console.error('Error reading ' + envResponse.env + ' configuration file');
                  resolve(error);
                  return Observable.throw(error.json().error || 'Server error');
                })
              )
              .subscribe((responseData) => {
                console.log('Configuration file loaded.');
                this.config = responseData;
                resolve(true);
              });
          } else {
            console.error('Env config file "env.json" is not valid');
            resolve(true);
          }
        });

    });
  }
}
