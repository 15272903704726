import { Directive, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {

  interval;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    this.el.nativeElement.focus();
    /*
    this.interval = setInterval(() => {
      console.log('tick');
      this.el.nativeElement.focus();
    }, 100);
    */
  }

  /*
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  */

}
