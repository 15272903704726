import { Directive, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appFakeclick]'
})
export class FakeclickDirective implements OnInit {

	constructor() {
  }

  ngOnInit() { }

	@HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
