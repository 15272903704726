import { Component, ElementRef, Inject, Input, OnInit, OnDestroy, Renderer, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { ClientInterface, OrderInterface, OrderedProductInterface, ProductInterface, UserInterface } from '../../interfaces/interfaces.type';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
//import { SweetAlert2Module, SwalComponent } from '@toverux/ngx-sweetalert2';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { HostListener } from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  host: { 'class': 'full-height' },

})
export class OrderComponent {
	addComment:boolean;
	changeQuantityChecked:boolean;
	client:ClientInterface;
	clientInformation:object = {
		"email" : "",
		"subscribeNewsletter" : false
	};
	comment:string;
	discount:number;
	discountedPriceMultiplier:number;
	errorMessage:string = "";
	languageParameters = {
		"productUPC": ""
	}
	emptyOrderSummary:OrderInterface = {
		date: "",
		id: 0,
		products: [],
		totals: {
			subtotal: 0,
			gst: 0,
			pst: 0,
			shipping: 0,
			total: 0
		}
	};
	modal:object = {
		"title" : "",
		"content" : "",
		"type" : ""
	};
	modalAlertRef:NgbModalRef;
	modalPaymentRef:NgbModalRef;
  orderDetail:OrderInterface;
	orderSummary:OrderInterface;
	paymentCashFromCustomer:number;
	paymentCashReturnCustomer:number;
	paymentMethod:string;
	paymentMethods:object;
	products:any = {};
	productSearchList:object[];
	productSearchSelectedOption:string;
	productUPC:any;
	scannedItem:ProductInterface;
	scannedItems:OrderedProductInterface[] = [];
	subscription:any;
	user:UserInterface;

	@BlockUI() blockUI: NgBlockUI;

	@ViewChild('productUPCField') productUPCElement : ElementRef;
	@ViewChild('successAudio') successAudioElement: ElementRef;
	@ViewChild('errorAudio') errorAudioElement: ElementRef;
	@ViewChild('warningAudio') warningAudioElement: ElementRef;
	@ViewChild('modalAlert') modalAlertElement: TemplateRef<any>;
	@ViewChild('modalPayment') modalPaymentElement: TemplateRef<any>;
//@ViewChild('deleteSwal') deleteSwal: SwalComponent;

	constructor(

    public dataService: DataService,
    public router: Router,
    public config: AppConfig,
    private authService: AuthService,
    private modalService: NgbModal,
    private renderer: Renderer,
    private translate: TranslateService
  ) {

	}

	/*
	Specific angular events
	*/

	//when the component is initialized
  ngOnInit() {
		console.log('===> Initialize Verify');

		this.initializeVariables();

		this.user = this.authService.getUser();

		this.paymentMethods = {
			"none" : { name : "", id : 'none' },
			"cash" : { name : this.translate.instant("order.buttons.payment-cash"), id : 'cash' },
			"interac" : { name : this.translate.instant("order.buttons.payment-interac"), id : 'interac' },
			"visa" : { name : this.translate.instant("order.buttons.payment-cc-visa"), id : 'visa' },
			"mastercard" : { name : this.translate.instant("order.buttons.payment-cc-mastercard"), id : 'mastercard' },
			"amex" : { name : this.translate.instant("order.buttons.payment-cc-amex"), id : 'amex' },
		};

		this.client = {
			client_number : '',
			name : ''
		}

		this.getData();
  }

	//when the component is destroyed
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

	/*
	Custom functions
	*/

	//sets a discount for every product
	applyDiscount() {

		do {
			this.playAudio('warning');
    	this.discount = parseFloat(window.prompt(this.translate.instant("interface.prompt.enter-discount"), ""));
		}	while(isNaN(this.discount) || this.discount > 100 || this.discount < 0);

		this.discountedPriceMultiplier = 1 - (this.discount/100);
		this.calculatePrice();
		this.summarizeOrder();
	}


	//calculate the change to give back to the client
	calculateChange(_newValue) {
		this.paymentCashReturnCustomer = this.setTotalDue(Number(parseFloat(_newValue.replace(/,/g, '.'))) - Number(this.setTotalDue(this.orderSummary.totals.total)));
		if (isNaN(this.paymentCashReturnCustomer)) {
			this.paymentCashReturnCustomer = 0;
		}
	}

	//calculate the price of each item to show the discounted price
	calculatePrice() {
		this.orderDetail.products.forEach(_product => {
			if (_product.apply_discount) {
				_product.price = Number((_product.price_original * this.discountedPriceMultiplier).toFixed(2));
				_product.total = Number(_product.quantity) * Number(_product.price);
			}
		});
	}

	//calculate totals
	calculateTotals() {

		let total_taxable_gst:number = 0;
		let total_taxable_pst:number = 0;
		let taxes = this.config.getConfig('taxes');

		this.orderSummary.totals = cloneDeep(this.emptyOrderSummary.totals);

		this.orderSummary.products.forEach(_product => {
			this.orderSummary.totals.subtotal += Number(_product.total.toFixed(2));

			if (_product.taxable_gst === "1") {
				total_taxable_gst += _product.total;
			}

			if (_product.taxable_pst === "1") {
				total_taxable_pst += _product.total;
			}

		});

		this.orderSummary.totals.gst = Number((total_taxable_gst * Number(taxes.gst)).toFixed(2));
		this.orderSummary.totals.pst = Number((total_taxable_pst * Number(taxes.pst)).toFixed(2));

		this.orderSummary.totals.total =  Number((this.orderSummary.totals.subtotal + this.orderSummary.totals.gst + this.orderSummary.totals.pst).toFixed(2));
	}

	//complete the order (save it to the database and send it to the printer)
	completeOrder() {
		if (this.paymentMethod != 'none') {

			let precisions:string = "";
			if (this.clientInformation['email'] !== '') {
				precisions += this.translate.instant("order.labels.email") + ': ' + this.clientInformation['email'] + ';';
			}
			if (this.clientInformation['subscribeNewsletter']) {
				precisions += ' ' + this.translate.instant("order.labels.newsletter");
			}

			//if (confirm("Voulez-vous vraiment envoyer la facture vers Acomba?")) {
				let order = {
					"products" : this.orderSummary.products,
					"total" : this.orderSummary.totals,
					"notes" : {
						"comment" : this.comment,
						"precisions" : precisions //save as precision because they don't get printed on the invoice
					},
					"payment" : this.paymentMethods[this.paymentMethod].id
				}

				this.blockUI.start();

				this.subscription = this.dataService.saveOrder(order).subscribe(
					(_data) => {
						if (_data) {
							if (_data.status === "success") {
								this.orderSummary.id = _data.order_ids.join(', ');
								this.orderSummary.date = _data.date;

								this.modalPaymentRef.close();

								this.modal = {
									"title" : '<i class="icon-favuzzi-circle-check text-success"></i>' + this.translate.instant("interface.modal.title.order-complete"),
									"content" : this.translate.instant("interface.modal.message.order-complete") + '<span class="order-number-large">#' + this.orderSummary.id + '</span>',
									"type" : "complete"
								}

								this.modalAlertRef = this.modalService.open(this.modalAlertElement, { backdrop: 'static', centered: true, keyboard: false, size : 'lg' });
								this.modalAlertRef.result.then((_result) => {
									if (_result === 'newOrder') {
										this.newOrder(false);
									}
								}, (_reason) => {
									console.log("reason", _reason);
								});

								this.playAudio('success');

								setTimeout(() => this.printOrder(), 500);

								this.blockUI.stop();
							}
						}
						else {
							alert('Error saving order. Please try again.');
							this.blockUI.stop();
						}
					},
					error => {
						alert('[Save Order] => There is a configuration problem with the server.');
						this.blockUI.stop();
					}
				);
			//}
		}
	}

	//show a popup when the product is not found
	errorProduct(_message:string) {

		/*
		this.modal = {
			"title" : this.translate.instant("interface.modal.title.warning"),
			"content" : _message,
			"type" : "warning"
		}

		this.deleteSwal.show();
		*/


		this.modal = {
			"title" : '<i class="icon-favuzzi-warning text-error"></i>' + this.translate.instant("interface.modal.title.warning"),
			"content" : _message,
			"type" : "alert"
		}

		this.modalAlertRef = this.modalService.open(this.modalAlertElement, { backdrop: 'static', centered: true });
		this.modalAlertRef.result.then((_result) => {
			//console.log('_result', _result);
		}, (_reason) => {
			//console.log("reason", _reason);
		});
		this.playAudio('error');
	}

	//generate a list with all the products for the search autocomplete
	generateProductSearchList() {
		this.products.forEach(_product => {
			this.productSearchList.push( {
																			'code': _product.code,
																			'name' : _product.code + ' - ' + _product.brand_fr + ' - ' + _product.name_fr + ' - ' + _product.format + ' - ' + Number(_product.price).toFixed(2) + '$'
																		}
																 );
		});
	}

	getData() {
		this.blockUI.start();

		this.subscription = this.dataService.getData().subscribe(
			(_data) => {
				console.log(_data);
				if (_data.products.length > 0) {
					console.log('Products have arrived');
					this.products = _data.products;
					this.client = _data.client;

					//get local products (custom 4 packs);
					this.dataService.getLocalProducts().subscribe(_localProducts => {
	          if (_localProducts) {
							_localProducts.forEach(a => this.products.push(a));
						}
	        });

					this.generateProductSearchList();
					this.blockUI.stop();
				}
				else {
					alert('Error getting products.');
				}
			},
			error => {
				alert('[Products] => There is a configuration problem with the server.');
			}
		);
	}

	//gets a specific html tag
	getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);
    for (let idx = 0; idx < elements.length; idx++) {
      htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
	}

	//initialize the variables
	initializeVariables() {
		this.addComment = false;
		this.changeQuantityChecked = false;
		this.clientInformation = {
			"email" : "",
			"subscribeNewsletter" : false
		};
		this.comment = "";
		this.discount = 0;
		this.discountedPriceMultiplier = 1;
		this.orderDetail = cloneDeep(this.emptyOrderSummary);
		this.orderSummary = cloneDeep(this.emptyOrderSummary);
		this.paymentCashFromCustomer = 0;
		this.paymentCashReturnCustomer = 0;
		this.paymentMethod = "none";
		this.productUPC = "";
		this.productSearchSelectedOption = "";
		this.productSearchList = [];
	}

	//when a upc, code, etc is submitted to the form
	inputEntered(_event) {

		if (this.productUPC !== '') {
	    this.languageParameters.productUPC = this.productUPC;

	    console.log('Search for input ' + this.productUPC);

	    let isCaseUPC:any = false;
	    let isProductUPC:any = false;
	    let isCode:any = false;

	    //lets see if it's a case upc
	    this.scannedItem = this.products.find(product => product.upc_case === this.productUPC);

	    if (this.scannedItem) {
	      //it's a case
	      isCaseUPC = true;
	    }
	    else {
	      //if it's not a case upc, let's see if it's a single product upc
	      this.scannedItem = this.products.find(product => product.upc === this.productUPC);

	      if (this.scannedItem) {
	        //it's a product
	        isProductUPC = true;
	      }
	      else {
	        //if it's not a product upc, let's see if it's a product code
	        this.scannedItem = this.products.find(product => product.code.toLowerCase() === this.productUPC.toLowerCase());

	        if (this.scannedItem) {
	          //it's a code
	          isCode = true;
	        }
	      }
	    }

	    //if we found a product matching the UPC (case, single product or code)
	    if (this.scannedItem) {
				console.log('Product found in product database');

				let price:any = 0;

				//validate that the product has a price. If not, prompt the user for one.
				if (Number(this.scannedItem.price) === 0) {
					//here we keep the prompt open until the user entered an amount.
					//we chekc that the price is less than 1000 in case the user doesn't notice the prompt and keeps scanning products.
					while ((price === 0) || (price >= 1000) || (isNaN(price))) {
						this.playAudio('warning');
						price = Number(prompt(this.translate.instant("interface.prompt.enter-price"), "").replace(/,/g, '.').replace(/[^0-9.]/g, ""));
					}
				}
				else {
					price = this.scannedItem.price;
				}


				let orderedProduct:OrderedProductInterface = {
					apply_discount : true,
					code : this.scannedItem.code,
					component_code : this.scannedItem.component_code,
					component_description : this.scannedItem.component_description,
					component_price : this.scannedItem.component_price,
					component_total : 0,
					format: this.scannedItem.format,
					id : this.scannedItem.id,
					is_component : false,
					name_en : this.scannedItem.name_en,
					name_fr : this.scannedItem.name_fr,
					price_original : price,
					price : price * this.discountedPriceMultiplier,
					quantity : 0,
					taxable_gst : this.scannedItem.taxable_gst,
					taxable_pst : this.scannedItem.taxable_pst,
					upc : this.scannedItem.upc,
					weight : this.scannedItem.weight,
					quantity_per_case : this.scannedItem.quantity_per_case
				}

				if (isCaseUPC) {
					//add the case quantity to the quantity
					orderedProduct.quantity += Number(this.scannedItem.quantity_per_case);
				}
				else if(isProductUPC || isCode) {

					let quantity:number = 1;

					if (this.changeQuantityChecked) {
						let promptOpened:boolean = false;
						quantity = 0;
						while ((!promptOpened) || (quantity >= 1000)) { //1000 in case the user scans a barcode in the input quantity
							this.playAudio('warning');
							promptOpened = true;
							quantity = Number(prompt(this.translate.instant("interface.prompt.change-quantity"), "1"));
						}
					}

					//add the quantity
					if (quantity) {
						orderedProduct.quantity += quantity;
					}
				}

				if (orderedProduct.quantity) {
					//check for components
					if (orderedProduct.component_code != null) {
						let orderedProductComponent:OrderedProductInterface = {
							apply_discount : false,
							code : orderedProduct.component_code,
							id : orderedProduct.id,
							is_component : true,
							format : "-",
							name_en : orderedProduct.component_description,
							name_fr : orderedProduct.component_description,
							price : orderedProduct.component_price,
							quantity : orderedProduct.quantity,
							taxable_gst : 'false',
							taxable_pst : 'false',
							upc : '-',
							weight : 0
						}

						this.orderDetail.products.unshift(orderedProductComponent); //add the component to the order
					}

					this.orderDetail.products.unshift(orderedProduct); //add the product to the order

					this.playAudio('success');

					this.summarizeOrder();
				}
				else {
					this.playAudio('error');
					alert(this.translate.instant("interface.prompt.quantity-error"));
				}
	    }
	    else {
	      //the upc was nowhere to be found, show error
	      this.errorProduct(this.translate.instant("order.errors.product-not-found"));
	    }

			this.resetForm();
			this.resetFocus();
			this.changeQuantityChecked = false; // reset the state

		}
  }

	//initializes a new order
	newOrder(_showConfirm = true) {
		if (_showConfirm) {
			this.playAudio('warning');
			if (confirm(this.translate.instant("interface.prompt.new-order"))) {
				this.getData();
				this.initializeVariables();
				this.resetForm();
				this.resetFocus();
			}
		}
		else {
			this.getData();
			this.initializeVariables();
			this.resetForm();
			this.resetFocus();
		}
	}

	//opens the payment popup
	payOrder() {
		if (this.orderSummary.products.length > 0) {
			this.paymentMethod = "none";
			this.modalPaymentRef = this.modalService.open(this.modalPaymentElement, { backdrop: 'static', centered: true, keyboard: true, size: 'lg', windowClass : "modal-payment" });
			this.modalPaymentRef.result.then((_result) => {
				//console.log('_result', _result);
			}, (_reason) => {
				//console.log("reason", _reason);
			});
		};
	}

	//when the user chooses an option from the search dropdown
	productSearchSelected(_event: TypeaheadMatch) {
		this.productUPC = _event.item.code
		this.productSearchSelectedOption = "";
		//this.productUPCElement.nativeElement.click();
		this.inputEntered(false);
	}

	//removes a product from the list while checking if it needs to remove a component product also
	removeProduct(_index) {
		if (this.orderDetail.products.length > 0) {
			this.playAudio('warning');
			if (confirm(this.translate.instant("interface.prompt.remove-product"))) {
				let product:OrderedProductInterface = this.orderDetail.products[_index];
				let quantityToRemove:number = 1;

				if (product.component_code != null) {
					quantityToRemove = 2;
				}

				this.orderDetail.products.splice(_index, quantityToRemove);

				this.summarizeOrder();
			}
		}

		this.resetFocus();
	}

	//sets the payment method
	setPaymentMethod(_method) {
		this.paymentMethod = _method;
	}

	//sets the total due for cash payments (needs to be rounded to the nearest cent)
	setTotalDue(_amount) {
		if (this.paymentMethod === 'cash') {
			_amount = (Math.ceil(_amount*20 - 0.5)/20).toFixed(2);
		}
		return _amount;
	}

	//print the order in a pop up window
	printOrder() {
		console.log('PRINT');
		const printContents = document.getElementById('invoice').innerHTML;
		const stylesHtml = this.getTagsHtml('style');
		const linksHtml = this.getTagsHtml('link');

		const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(`
		    <html>
		        <head>
		            <title>Print tab</title>
		            ${linksHtml}
		            ${stylesHtml}
		        </head>
		        <body onload="window.print(); window.close()">
		            ${printContents}
		        </body>
		    </html>
		    `
		);
		popupWin.document.close();
	}

	//play a spcific audio file in the html template
	playAudio(_state) {
		if (_state === 'success') {
			this.successAudioElement.nativeElement.currentTime = 0;
			this.successAudioElement.nativeElement.play();
		}
		else if (_state === 'error') {
			this.errorAudioElement.nativeElement.currentTime = 0;
			this.errorAudioElement.nativeElement.play();
		}
		else if (_state === 'warning') {
			this.warningAudioElement.nativeElement.currentTime = 0;
			this.warningAudioElement.nativeElement.play();
		}
	}

	//reset the focus on the product upc field
	resetFocus() {
    this.renderer.invokeElementMethod(this.productUPCElement.nativeElement, 'focus');
  }

	//reset the form
  resetForm() {
    this.productUPC = "";
  }

	//calculates all the products, quantities, etc
	summarizeOrder() {
		this.orderSummary = cloneDeep(this.emptyOrderSummary); //reset the object

		this.orderDetail.products.forEach(_product => {
			let orderedProduct:OrderedProductInterface = this.orderSummary.products.find(product => ((product.code === _product.code) && (product.price === _product.price)));
			//check if the product was already added to the summary
			let productAlreadyAdded = (orderedProduct === undefined ? false : true);

			if (!productAlreadyAdded) {
				console.log('Product is new to the summary');
				orderedProduct = Object.assign({}, _product);
				this.orderSummary.products.push(orderedProduct);
			}
			else {
				console.log('Product already in the summary');
				orderedProduct.quantity += _product.quantity;
			}

			orderedProduct.total = Number(orderedProduct.quantity) * Number(orderedProduct.price);

			if (orderedProduct.component_code != null) {
				orderedProduct.component_total = Number(orderedProduct.quantity) * Number(orderedProduct.component_price);
			}

		});

		//sort by code
		this.orderSummary.products.sort(function(a, b){
	    if ( a.code < b.code )
        return -1;
	    if ( (a.code > b.code) )
        return 1;
	    return 0;
		});


		/*
		let componentCodes:string[] = ['DEP01', 'DEP02', 'DEP03'];

		let depositProduct:OrderedProductInterface = this.orderSummary.products.find(product => (product.code === "DEP01"));

		if (depositProduct) {
			this.orderSummary.products.push(this.orderSummary.products.splice(this.orderSummary.products.indexOf(depositProduct), 1)[0]);
		}
		*/

		this.calculateTotals();
	}

	//when a tab is changed
	tabChange() {
		//used to refresh the scrollbar on the list
		setTimeout(function() {
			window.dispatchEvent(new Event('resize'));
		}, 500);
		this.resetFocus();
	}

	//Host listeners for specific window events
	@HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'CapsLock') {
			this.changeQuantityChecked = !this.changeQuantityChecked;
		}
  }

	@HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("Processing beforeunload...");
    if (this.orderSummary.products.length > 0) {
    	event.returnValue = false;
		}
  }
}
