// Angular modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { BlockUIModule } from 'ng-block-ui';
//import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Localize dates, currency, etc
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFRFR from '@angular/common/locales/fr';
registerLocaleData(localeFRFR);

//Config
import { APP_INITIALIZER } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppConfig } from './app.config';

// Components
import { AppComponent } from './app.component';

// Interface
import { HeaderComponent } from './components/main/header/header.component';
import { FooterComponent } from './components/main/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/main/logout/logout.component';
import { OrderComponent } from './components/order/order.component';

// Services
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';

// Guards
import { AuthGuard } from './auth.guard';

// Directives
import { AutofocusDirective } from './directives/autofocus.directive';
import { FakeclickDirective } from './directives/fakeclick.directive';
import { MatchHeightDirective } from './directives/match-height.directive';


const appRoutes:Routes = [
  {
    path : '',
    component : LoginComponent
  },
  {
    path : 'logout',
    component : LogoutComponent
  },
	{
    path : 'order',
    component : OrderComponent,
		pathMatch: "full",
    canActivate: [AuthGuard]
  },
  {
    path : '**',
    redirectTo : ''
  }
]

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    LogoutComponent,
		OrderComponent,
    AutofocusDirective,
    FakeclickDirective,
    MatchHeightDirective,
  ],
  imports: [
    BlockUIModule.forRoot(),
    Angular2PromiseButtonModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    NgbModule.forRoot(),
		TypeaheadModule.forRoot(),
    RouterModule.forRoot(appRoutes, {useHash: true}),
		ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
		/*SweetAlert2Module.forRoot({
        buttonsStyling: false,
        customClass: 'modal-content',
        confirmButtonClass: 'btn btn-primary',
        cancelButtonClass: 'btn'
    }),*/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    AuthGuard,
    AuthService,
    DataService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig],
      multi: true
    }

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
