import { Component, OnInit } from '@angular/core';
import { map, take } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
	host: { 'id': 'header' },

})
export class HeaderComponent implements OnInit {

  constructor( ) { }

  ngOnInit() {
  }

}
