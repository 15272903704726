import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  host: { 'class': 'logout-container' },

})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {

  }

  logout() {
    this.authService.logout().subscribe((_response) => {
      if (_response.status === 'success') {
        console.log('User logged out successfully');
        this.router.navigate(['/']);
      }
    });
		return false;
  }

}
